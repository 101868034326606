const PANEL_BE_URL = process.env.REACT_APP_API_END_POINT

export const PANEL_DASHBOARD_URL = '/policies'
export const PANEL_LOGIN_URL = '/login'
export const CHANGE_PASSWORD_PATH = '/change-password'

// login end points
export const LOGIN_API = PANEL_BE_URL + 'login'
export const CHANGE_PASSWORD_API = PANEL_BE_URL + 'employee/profile/password'
export const CHECK_USER_ORIGIN = PANEL_BE_URL + 'login/origin'
export const OTP_API = PANEL_BE_URL + 'login/submit-otp'

// Employee API endpoints
export const POLICY_LIST_API = PANEL_BE_URL + 'employee/policy'

// Chapter APIs
export const CHAPTER_LIST_API = PANEL_BE_URL + 'employee/chapter/docs'

import React, { lazy } from 'react'
import Login from '../components/auth/Login'
import ChangePassword from '../components/auth/ChangePassword'
import OtpValidation from '../components/auth/OtpValidation'

const Policies = lazy(() => import('../components/employee/Employee'))
const Chapters = lazy(() => import('../components/chapters/ChaptersWrapper'))

export const ROUTING_WITH_SIDEBAR = (props) => {
  const {
    actions,
    employeeData,
    chaptersData
  } = props
  return [
    {
      path: '/policies',
      key: 'POLICIES',
      exact: true,
      component: <Policies
        actions={actions}
        employeeData={employeeData}
                 />
    },
    {
      path: '/policies/:policyId',
      key: 'POLICIES_DETAIL',
      exact: true,
      component: <Policies
        actions={actions}
        employeeData={employeeData}
                 />
    },
    {
      path: '/chapters',
      key: 'CHAPTERS',
      exact: true,
      component: <Chapters
        actions={actions}
        chaptersData={chaptersData}
                 />
    },
    {
      path: '/chapters/:chapterId',
      key: 'CHAPTERS_DETAIL',
      exact: true,
      component: <Chapters
        actions={actions}
        chaptersData={chaptersData}
                 />
    }

  ]
}

export const ROUTING_WITHOUT_SIDEBAR = (props) => {
  const { actions, loginData } = props
  return [
    {
      path: '/login',
      key: 'LOGIN',
      exact: true,
      component: <Login
        actions={actions}
        loginData={loginData}
                 />
    },
    {
      path: '/change-password',
      key: 'CHANGE_PASSWRD',
      exact: true,
      component: (
        <ChangePassword
          actions={actions}
          loginData={loginData}
        />
      )
    },
    {
      path: '/validate',
      key: 'VALIDATE_OTP',
      exact: true,
      component: <OtpValidation
        actions={actions}
        loginData={loginData}
                 />
    },
    {
      path: '*',
      key: 'ROUTE_NOT_MATCHED',
      exact: true,
      component: <Login
        actions={actions}
        loginData={loginData}
                 />
    }
  ]
}

import * as actionTypes from '../constants/actionTypes'
import * as initialStates from '../constants/initialStates'

function getSelectedPolicyIndex (currentPolicy, state) {
  if (currentPolicy && currentPolicy.id && state.policyList.length) {
    const index = state.policyList.findIndex(element => element.id === currentPolicy.id)
    return index
  }
  return -1
}

export default function employeeReducer (state = initialStates.EMPLOYEE_INITIAL_STATE, action) {
  let indexToSearch, listToParse

  switch (action.type) {
    case actionTypes.UPDATE_POLICY_LIST:
      return Object.assign({}, state, {
        policyList: action.data,
        fetchingPolicyList: false
      })

    case actionTypes.FETCHING_POLICY_LIST:
      return Object.assign({}, state, {
        fetchingPolicyList: action.data
      })

    case actionTypes.UPDATE_CURRENT_POLICY_INFO:
      return Object.assign({}, state, {
        currentPolicyInfo: action.data,
        docPreviewURL: action?.data?.signed_get_url
      })

    case actionTypes.UPDATE_POLICY_META_DATA:
      indexToSearch = getSelectedPolicyIndex(action.data, state)
      listToParse = [...state.policyList]
      if (indexToSearch >= 0 && listToParse && listToParse[indexToSearch]) {
        listToParse[indexToSearch] = action.data
        if (state.policyList[indexToSearch].pinned !== action.data.pinned) {
          if (action.data.pinned) {
            const removedElement = listToParse.splice(indexToSearch, 1)
            listToParse.unshift(...removedElement)
          }
        }
      }
      return Object.assign({}, state, {
        policyList: listToParse,
        currentPolicyInfo: action.data
      })

    case actionTypes.UPDATE_PREVIEW_URL:
      return Object.assign({}, state, {
        docPreviewURL: action.data
      })

    default:
      return state
  }
}

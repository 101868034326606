import { useEffect, useRef } from 'react'
import { USER_ORIGIN } from '../../constants/siteConstants'

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve()
    const script = document.createElement('script')
    script.src = src
    script.onload = () => resolve()
    script.onerror = (err) => reject(err)
    document.body.appendChild(script)
  })
const GoogleAuth = (props) => {
  const { actions, email, loginData, navigate } = props
  const googleButton = useRef(null)
  const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_EMBEDDED_LOGIN_URL
  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client'
    loadScript(src)
      .then(() => {
        /* global google */
        google.accounts.id.initialize({
          client_id: GOOGLE_CLIENT_ID,
          callback: handleCredentialResponse
        })
        google.accounts.id.renderButton(
          googleButton.current,
          {
            theme: 'filled_blue',
            size: 'large',
            width: 180,
            text: 'continue_with',
            height: document.getElementById('login-button')?.offsetHeight
          }
        )
      })
      .catch(console.error)

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`)
      if (scriptTag) document.body.removeChild(scriptTag)
    }
  }, [])

  function handleCredentialResponse (response) {
    if (response.credential) {
      actions.loginActions.tryLoginWithEmail({ username: email, origin: USER_ORIGIN.GSUITE, token: response.credential, organization_id: loginData?.selectedOrgId }, null, navigate)
    }
  }

  return (
    <div className='g-auth-button'>
      <div ref={googleButton} />
    </div>
  )
}

export default GoogleAuth

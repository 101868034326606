import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import InputWithHeading from '../shared/InputWithHeading'
import ButtonContainer from '../shared/ButtonContainer'

import '../../assets/stylesheets/components/login/login.scss'
import GoogleAuth from './GoogleAuth'
import AzureAuth from './AzureAuth'
import { USER_ORIGIN } from '../../constants/siteConstants'
import { Dropdown } from 'primereact/dropdown'
import { useNavigate } from 'react-router-dom'

function Login (props) {
  const [state, setState] = useState({
    username: '',
    password: '',
    usernameError: false,
    passwordError: false,
    showPassword: false
  })
  const navigate = useNavigate()
  const { actions, loginData } = props
  const [selectedUserOrg, updateSelectedUserOrg] = useState(null)

  useEffect(() => {
    if (loginData.originData?.length) {
      updateSelectedUserOrg(loginData.originData[0])
    }
  }, [loginData.originData])

  function onPasswordFocusOut (value) {
    setState({ ...state, password: value })
  }

  function onPasswordFocus () {
    setState({ ...state, passwordError: false })
    actions.loginActions.resetErrors()
  }

  const onUsernameFocus = () => {
    setState({ ...state, usernameError: false })
    actions.loginActions.resetErrors()
  }

  const onUsernameFocusOut = (value) => {
    setState({ ...state, username: value })
  }

  function onLoginCTAClick () {
    if (loginData.userOrigin) {
      let usernameError = false
      let passwordError = false

      if (state.username && state.password) {
        actions.loginActions.tryLoginWithEmail({ username: state.username, origin: USER_ORIGIN.PASSWORD, password: state.password, organization_id: selectedUserOrg.organization_id }, loginData.userOrigin, navigate)
      } else {
        if (!state.username) { usernameError = true }
        if (!state.password) { passwordError = true }

        setState({ ...state, passwordError, usernameError })
      }
    } else {
      let usernameError = false

      if (state.username) {
        actions.loginActions.tryCheckingUserOrigin(state)
      } else {
        if (!state.username) { usernameError = true }

        setState({ ...state, usernameError })
      }
    }
  }

  function togglePassword () {
    setState({
      ...state,
      showPassword: !state.showPassword
    })
  }

  function changeEmail () {
    actions.loginActions.onlogoutSuccess()
    setState({
      ...state,
      username: '',
      password: ''
    })
  }

  const updateSelectedOrigin = (selectedOrg) => {
    updateSelectedUserOrg(selectedOrg)
    actions.loginActions.updateSelectedUserOrigin(selectedOrg)
  }

  const listTemplate = (option) => {
    return (
      <div className='vertical-center'>
        <span className='tl-text12'>{option?.organization_name}</span>
        <span className='tl-text12 tl-ml12 tl-166EFC'>({option?.role})</span>
      </div>
    )
  }

  return (
    <div className='panel-login-component'>
      <div className='logo-wrapper tc tl-p32 flex-center'>
        <a href='https://trustspace.io/'><img className='tl-mt6' height='24px' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/TrustSpace-logo.png' alt='TrustSpace' /></a>
        <span className='tl-text26 tl-ml12 tl-cffffff tl-f500'>TrustSpace</span>
      </div>
      <div className='login-content-inputs tc'>
        <div className='header-text tl-text40 tl-f600 tl-lh170 tl-cffffff '>Log In</div>
        <div className='tl-text16 tl-fsn tl-fnormal tl-mb20  tl-9CACC9'>
          {!loginData.userOrganizationName ? 'Welcome to TrustSpace, please put your login below to start using our service' : 'Welcome, ' + loginData.userOrganizationName}
        </div>
        <div className='panel-login-password-container '>
          {loginData.originData?.length > 1 &&
            <div>
              <p className='input-label tl required'>Organisation</p>
              <div className='w-100 tl tl-mb20'>
                <Dropdown
                  className='w-100'
                  value={selectedUserOrg} options={loginData.originData} onChange={(e) => updateSelectedOrigin(e.value)} optionLabel='organization_name' placeholder='Select organization to proceed'
                  itemTemplate={listTemplate} valueTemplate={listTemplate}
                />
              </div>
            </div>}
          <div className='p-relative'>
            <InputWithHeading
              id='frmEmailA'
              inputHeading='Email'
              placeholder='Enter your email'
              value={state.username}
              errorText='Please enter your correct username'
              hasError={state.usernameError}
              onBlur={onUsernameFocusOut}
              onFocus={onUsernameFocus}
              onChange={onUsernameFocusOut}
              onEnter={onLoginCTAClick}
              autoComplete
              name='email'
              inputType='email'
              checkIcon={loginData.userOrigin}
            />
            {loginData.userOrigin && <span className='change-email' onClick={changeEmail}>Change Email</span>}
          </div>
          {loginData.userOrigin && !(selectedUserOrg?.disabled_password_login) &&
            <div className='password-holder'>
              <InputWithHeading
                inputHeading='Password'
                placeholder='Enter your password'
                errorText='The password was entered incorrectly'
                hasError={state.passwordError}
                onBlur={onPasswordFocusOut}
                onFocus={onPasswordFocus}
                onChange={onPasswordFocusOut}
                onEnter={onLoginCTAClick}
                inputType={state.showPassword ? 'text' : 'password'}
                isRequired={loginData.userOrigin}
              />

              {state.showPassword
                ? (
                  <span className='toggle-pass cursor-hand fa fa-eye ibm cp' onClick={togglePassword} />
                  )
                : (
                  <span className='toggle-pass cursor-hand  fa fa-eye-slash ibm cp' onClick={togglePassword} />
                  )}

            </div>}
          <div className='vertical-center'>
            <div id='login-button' className={(loginData.userOrigin === USER_ORIGIN.GSUITE || loginData.userOrigin === USER_ORIGIN.AZURE) ? 'w-50' : 'w-100'}>
              <ButtonContainer
                btnText='Log In'
                isInactive={false}
                updateInProgress={loginData.loginInProgress}
                onBtnClick={onLoginCTAClick}
              />
            </div>
            {loginData.userOrigin === USER_ORIGIN.GSUITE &&
              <div className='tl-mb20'>
                <GoogleAuth actions={actions} email={state.username} loginData={loginData} navigate={navigate} />
              </div>}
            {loginData.userOrigin === USER_ORIGIN.AZURE &&
              <div className='tl-mb20'>
                <AzureAuth actions={actions} email={state.username} loginData={loginData} navigate={navigate} />
              </div>}
          </div>

          {loginData.loginAttemptFailed && (
            <div className='input-heading-container error '>
              <div className='error-text-container'>
                <span className='error-text tl-text12'>Login Attempt Failed.Please check credentials and try again</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

Login.propTypes = {
  actions: PropTypes.object.isRequired,
  loginData: PropTypes.object.isRequired
}

export default Login

import * as endpoints from '../constants/endpoints'
import axios from 'axios'
import { getCommonHeaders, showToaster } from '../helpers/Utils'

export async function enterOTPAndLogin (data) {
  try {
    const res = await axios.post(endpoints.OTP_API, JSON.stringify(data), { headers: getCommonHeaders() })
    return res.data
  } catch (error) {
    showToaster({ type: 'error', label: 'Attention !', message: error?.response?.data?.message })
  }
}

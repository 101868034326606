export const CookiesHelper = {
  /* eslint-disable */
    getItem: function (sKey) {
        return (
            decodeURIComponent(
                document.cookie.replace(
                    new RegExp(
                        '(?:(?:^|.*;)\\s*' +
                        encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') +
                        '\\s*\\=\\s*([^;]*).*$)|^.*$'
                    ),
                    '$1'
                )
            ) || null
        )
    },
    setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
        if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
            return false
        }
        let sExpires = ''
        if (vEnd) {
            switch (vEnd.constructor) {
                case Number:
                    sExpires =
                        vEnd === Infinity
                            ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT'
                            : '; max-age=' + vEnd
                    break
                case String:
                    sExpires = '; expires=' + vEnd
                    break
                case Date:
                    sExpires = '; expires=' + vEnd.toUTCString()
                    break
            }
        }
        document.cookie =
            encodeURIComponent(sKey) +
            '=' +
            encodeURIComponent(sValue) +
            sExpires +
            (sDomain ? '; domain=' + sDomain : '') +
            (sPath ? '; path=' + sPath : '') +
            (bSecure ? '; secure' : '')
        return true
    },
    removeItem: function (sKey, sPath, sDomain) {
        if (!sKey || !this.hasItem(sKey)) {
            return false
        }
        document.cookie =
            encodeURIComponent(sKey) +
            '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
            (sDomain ? '; domain=' + sDomain : '') +
            (sPath ? '; path=' + sPath : '')
        return true
    },
    hasItem: function (sKey) {
        return new RegExp(
            '(?:^|;\\s*)' +
            encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') +
            '\\s*\\='
        ).test(document.cookie)
    },
    keys: /* optional method: you can safely remove it! */ function () {
        const aKeys = document.cookie
            .replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, '')
            .split(/\s*(?:\=[^;]*)?;\s*/)
        for (let nIdx = 0; nIdx < aKeys.length; nIdx++) {
            aKeys[nIdx] = decodeURIComponent(aKeys[nIdx])
        }
        return aKeys
    }
}

export function getCommonHeaders() {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + ' ' + localStorage.getItem('loginToken')
    }
    return headers
}

export function showToaster(dataObject) {
    var evt = new CustomEvent("showToaster", { detail: dataObject });
    window.dispatchEvent(evt);
}

export function isLoggedIn() {
    const loginToken = localStorage.getItem('loginToken')
    return loginToken && loginToken.length
}

export function pushUrlToState(url, title) {
    window.history && window.history.pushState({}, title || '', url)
}

export function downloadFile(url, name) {
    const link = document.createElement('a')
    if (name) {
        link.download = name
    }
    link.href = url
    link.click()
}

export function getFormattedDate(givenDate) {
    if (givenDate) {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        const d = new Date(givenDate)
        const date = d.getDate()
        const month = months[d.getMonth()]
        const year = d.getFullYear()
        const formattedDate = date + '-' + month + '-' + year
        return formattedDate
    }
}

export function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&")// This is just to avoid case sensitiveness for query parameter name
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2]);
  }
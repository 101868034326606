import React, { useState } from 'react'
import PropTypes from 'prop-types'

import InputWithHeading from '../shared/InputWithHeading'
import ButtonContainer from '../shared/ButtonContainer'

import '../../assets/stylesheets/components/login/login.scss'

function ChangePassword (props) {
  const [state, setState] = useState({
    password: '',
    confirmPassword: '',
    oldPasswordError: false,
    newPasswordError: false
  })

  const { actions, loginData } = props

  function onNewPasswordFocusOut (value) {
    setState({ ...state, confirmPassword: value })
  }

  function onNewPasswordFocus () {
    setState({ ...state, newPasswordError: false })
    actions.loginActions.resetErrors()
  }

  const onPasswordFocus = () => {
    setState({ ...state, oldPasswordError: false })
    actions.loginActions.resetErrors()
  }

  const onPasswordFocusOut = (value) => {
    setState({ ...state, password: value })
  }

  function onPWChangeCTAClick () {
    let oldPasswordError = false
    let newPasswordError = false

    if (state.password && state.confirmPassword && (state.password === state.confirmPassword)) {
      actions.loginActions.tryChangingUserPassword(state)
    } else {
      if (!state.password) { oldPasswordError = true }
      if (!state.confirmPassword || (state.password !== state.confirmPassword)) { newPasswordError = true }

      setState({ ...state, newPasswordError, oldPasswordError })
    }
  }

  return (
    <div className='panel-login-component'>
      <div className='logo-wrapper tc tl-p32 flex-center'>
        <a href='https://trustspace.io/'><img className='tl-mt6' height='24px' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/TrustSpace-logo.png' alt='TrustSpace' /></a>
        <span className='tl-text26 tl-ml12 tl-cffffff tl-f500'>TrustSpace</span>
      </div>
      <div className='login-content-inputs tc'>
        <div className='header-text tl-text32 tl-mb32 tl-f600 tl-lh170 tl-cffffff '>Create New Password</div>
        <div className='panel-login-password-container '>
          <div>
            <InputWithHeading
              id='frmEmailA'
              inputHeading='Password'
              placeholder='Enter your password'
              value={state.password}
              errorText='Please enter your correct password'
              hasError={state.oldPasswordError}
              onBlur={onPasswordFocusOut}
              onFocus={onPasswordFocus}
              onChange={onPasswordFocusOut}
              onEnter={onPWChangeCTAClick}
              autoComplete={false}
              name='email'
              inputType='email'
            />
          </div>
          <div className='password-holder'>
            <InputWithHeading
              inputHeading='Confirm Password'
              placeholder='Re enter password'
              errorText='The password does not match the conditions'
              hasError={state.newPasswordError}
              onBlur={onNewPasswordFocusOut}
              onFocus={onNewPasswordFocus}
              onChange={onNewPasswordFocusOut}
              onEnter={onPWChangeCTAClick}
              autoComplete={false}
              inputType='text'
            />
          </div>
          <div>
            <ButtonContainer
              btnText='Update New Password'
              isInactive={false}
              updateInProgress={loginData.loginInProgress}
              onBtnClick={onPWChangeCTAClick}
            />
          </div>
          {loginData.loginAttemptFailed && (
            <div className='input-heading-container error '>
              <div className='error-text-container'>
                <span className='error-text tl-text12'>Change password Attempt Failed.Please check credentials and try again</span>
              </div>
            </div>
          )}
          <div className='error-text-container' style={{ backgroundColor: '#f2dede', color: '#a94442' }}>
            <span className='error-text tl-text12'>Your new password must contain at least a lower case, an upper case, a special char and a number !</span>
          </div>
        </div>
      </div>
    </div>
  )
}

ChangePassword.propTypes = {
  actions: PropTypes.object.isRequired,
  loginData: PropTypes.object.isRequired
}

export default ChangePassword

import React from 'react'

import './assets/stylesheets/styleGuides/panelMargins.scss'
import './assets/stylesheets/styleGuides/panelPadding.scss'
import './assets/stylesheets/styleGuides/panelTypography.scss'
import './assets/stylesheets/styleGuides/panelUtility.scss'
import './assets/stylesheets/styleGuides/colors.scss'
import './assets/stylesheets/styleGuides/animation.scss'
import './assets/stylesheets/shared/primeReactModification.scss'

import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'

import Dashboard from './components/dashboard/Dashboard'

function App (props) {
  return (
    <Dashboard />
  )
}

export default App

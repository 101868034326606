import React from 'react'
import { loginRequest, msalConfig } from './authConfig'
import { PublicClientApplication } from '@azure/msal-browser'
import { USER_ORIGIN } from '../../constants/siteConstants'

function AzureAuth (props) {
  const msalInstance = new PublicClientApplication(msalConfig)
  const { actions, email, loginData, navigate } = props

  const handleLogin = () => {
    msalInstance.loginPopup(loginRequest).then(response => {
      if (response.accessToken) {
        actions.loginActions.tryLoginWithEmail({ username: email, origin: USER_ORIGIN.AZURE, token: response.accessToken, organization_id: loginData?.selectedOrgId }, null, navigate)
      }
    })
      .catch(e => {
        console.warn(e)
      })
  }
  return (
    <div className='azure-auth-wrapper'>
      <div className='bsk-container' onClick={() => handleLogin()}>
        <div className='bsk-btn bsk-btn-default blue-cta vertical-center'>
          <img src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/Brands/microsoft-icon.png' alt='MS-logo' height='18px' width='18px' />
          <span className='tl-text14 tl-ml8'>Continue with Microsoft</span>
        </div>
      </div>
    </div>
  )
}

export default AzureAuth

export const LOGIN_INITIAL_STATE = {
  loginInProgress: false,
  loginAttemptFailed: false,
  loginFlowComplete: true,
  userOrigin: null,
  userOrganizationName: '',
  originData: [],
  selectedOrgId: null,
  userDetails: {}
}

export const EMPLOYEE_INITIAL_STATE = {
  policyList: [],
  fetchingPolicyList: false,
  currentPolicyInfo: {},
  docPreviewURL: ''
}

export const CHAPTERS_INITIAL_STATE = {
  chaptersList: [],
  fetchingChaptersList: false,
  currentChapterInfo: {},
  docPreviewURL: '',
  totalChapterCount: 0
}

import * as actionTypes from '../constants/actionTypes'
import * as initialStates from '../constants/initialStates'

function getSelectedChapterIndex (currentPolicy, state) {
  if (currentPolicy && currentPolicy.id && state.chaptersList.length) {
    const index = state.chaptersList.findIndex(element => element.id === currentPolicy.id)
    return index
  }
  return -1
}

export default function chaptersReducer (state = initialStates.CHAPTERS_INITIAL_STATE, action) {
  let indexToSearch, listToParse

  switch (action.type) {
    case actionTypes.UPDATE_CHAPTERS_LIST:
      return Object.assign({}, state, {
        chaptersList: action.data.chapter_docs,
        fetchingChaptersList: false,
        totalChapterCount: action.data.total_count
      })

    case actionTypes.FETCHING_CHAPTERS_LIST:
      return Object.assign({}, state, {
        fetchingChaptersList: action.data
      })

    case actionTypes.UPDATE_CURRENT_CHAPTER_INFO:
      return Object.assign({}, state, {
        currentChapterInfo: action.data,
        docPreviewURL: action.data.signed_get_url
      })

    case actionTypes.UPDATE_CHAPTER_META_DATA:
      indexToSearch = getSelectedChapterIndex(action.data, state)
      listToParse = [...state.chaptersList]
      if (indexToSearch >= 0 && listToParse && listToParse[indexToSearch]) {
        listToParse[indexToSearch] = action.data
        if (state.chaptersList[indexToSearch].pinned !== action.data.pinned) {
          if (action.data.pinned) {
            const removedElement = listToParse.splice(indexToSearch, 1)
            listToParse.unshift(...removedElement)
          }
        }
      }
      return Object.assign({}, state, {
        chaptersList: listToParse,
        currentChapterInfo: action.data
      })

    case actionTypes.UPDATE_CHAPTER_PREVIEW_URL:
      return Object.assign({}, state, {
        docPreviewURL: action.data
      })

    default:
      return state
  }
}

import React from 'react'
import { PLACEHOLDER_IMAGE_URL } from '../../constants/siteConstants'
import '../../assets/stylesheets/shared/topHeader.scss'

function TopHeader (props) {
  const userData = localStorage.getItem('userSession') && JSON.parse(localStorage.getItem('userSession'))
  return (
    <div className='top-header-wrapper tl-12 tl-pr12'>
      <div className='ibm tc tl-mt20'>
        <div className='notification-area tl-mr20 fa fa-bell tl-BCC4D1' />
        <div className='ibm'>
          <span className='tl-mr8 ibm tc'>
            <img className='border-r-50' alt='Logo' height='40px' src={userData?.image ? userData?.image : PLACEHOLDER_IMAGE_URL} />
          </span>
          <span className='user-detail ibm tc tl-mr8 tl-text16 tl-f500 tl-fsn tl-001233'>{userData?.name}</span>
          <span className='fa fa-angle-down tl-BCC4D1' />
        </div>
      </div>
    </div>
  )
}

export default TopHeader

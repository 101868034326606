import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from '../Sidebar/Sidebar'
import classnames from 'classnames'
import TopHeader from './TopHeader'
import Loading from './Loading'

function SidebarLayout (props) {
  const { actions } = props
  const [isMenuExpanded, updateMenuExpander] = useState(false)

  return (
    <>
      <TopHeader />
      <Sidebar
        actions={actions}
        updateMenuExpander={updateMenuExpander}
      />
      <main className={classnames('s-layout__content w-100 tl-p16', {
        'expanded-menu': !isMenuExpanded,
        'shrinked-menu': isMenuExpanded
      })}
      >
        <React.Suspense fallback={<Loading />}>
          <Outlet />
        </React.Suspense>
      </main>
    </>
  )
}

export default SidebarLayout

import React from 'react'
import PropTypes from 'prop-types'
import '../../assets/stylesheets/shared/buttonContainer.scss'

function ButtonContainer (props) {
  function handleClick (e) {
    props.onBtnClick && typeof props.onBtnClick === 'function' && props.onBtnClick()
  }

  return (
    <div className='quote-container textAlignC'>
      <div
        className={`quote-link-container ${props.btnClass} ${props.isInactive ? 'inactive' : ''} ${props.updateInProgress ? 'adjust-padding' : ''}`}
        onClick={handleClick}
      >
        {props.updateInProgress
          ? (
            <span className='white-loader fa fa-spinner' />
            )
          : (
            <span>
              <span className='quote-link cta tl-mr8' dangerouslySetInnerHTML={{ __html: props.btnIcon }} />
              <span className='quote-link cta tl-text14 tl-f500 tl-lh170 tl-fsn'>
                {props.btnText}
              </span>
            </span>
            )}
      </div>
      {props.btnHelperText &&
        (
          <div className='cta-helper'>
            <span className='text big-copy-heavy'>
              {props.btnHelperText}
            </span>
          </div>
        )}
    </div>
  )
}

ButtonContainer.propTypes = {
  btnText: PropTypes.string,
  btnHelperText: PropTypes.string,
  isInactive: PropTypes.bool,
  btnClass: PropTypes.string,
  handleClick: PropTypes.func
}

export default ButtonContainer

import * as actionTypes from '../constants/actionTypes'
import * as utils from '../helpers/Utils'
import * as endpoints from '../constants/endpoints'
import * as siteConstants from '../constants/siteConstants'
import { getData, putData } from '../helpers/apiCall'

export function updatePolicyList (data) {
  return { type: actionTypes.UPDATE_POLICY_LIST, data }
}

export function fetchingPolicyList (data) {
  return { type: actionTypes.FETCHING_POLICY_LIST, data }
}

export function updateCurrentPolicyInfo (data) {
  return { type: actionTypes.UPDATE_CURRENT_POLICY_INFO, data }
}

export function updatePolicyMetaData (data) {
  return { type: actionTypes.UPDATE_POLICY_META_DATA, data }
}

export function updatePreviewURL (data) {
  return { type: actionTypes.UPDATE_PREVIEW_URL, data }
}

export function tryUpdatingPolicyMetaData (policyId, dataToSend) {
  return function (dispatch) {
    const endpoint = endpoints.POLICY_LIST_API + '/' + policyId
    putData(endpoint, dataToSend)
      .then(jsonResponse => {
        if (jsonResponse && jsonResponse.code === siteConstants.SUCCESS_STATUS_CODE) {
          utils.showToaster({ type: 'success', label: 'Success Message', message: jsonResponse.message })
          dispatch(updatePolicyMetaData(jsonResponse.data))
        } else if (jsonResponse && jsonResponse.message) {
          utils.showToaster({ type: 'error', label: 'Success Message', message: jsonResponse.message })
        }
      })
  }
}

export function tryFetchingEmployeePolicies (searchedText, category) {
  return function (dispatch) {
    dispatch(fetchingPolicyList(true))
    let queryString = ''
    if (searchedText && category) {
      queryString += ('?searchString=' + searchedText + '&category=' + category)
    } else if (searchedText) {
      queryString += ('?searchString=' + searchedText)
    } else if (category) {
      queryString += ('?category=' + category)
    }

    if (queryString !== '') {
      queryString += '&showImplemented=true'
    } else {
      queryString += '?showImplemented=true'
    }

    getData(endpoints.POLICY_LIST_API + queryString)
      .then(jsonResponse => {
        if (jsonResponse && jsonResponse.code === siteConstants.SUCCESS_STATUS_CODE) {
          dispatch(updatePolicyList(jsonResponse.data))
        }
      })
  }
}

export function tryGettingSignedURL (dataToBeSend, isViewFlow) {
  return function (dispatch) {
    let queryString = ''
    if (dataToBeSend && dataToBeSend.action) {
      queryString += ('/' + dataToBeSend.action
      )
    }
    if (dataToBeSend && dataToBeSend.id) {
      queryString += ('/' + dataToBeSend.id
      )
    }
    if (dataToBeSend && dataToBeSend.version) {
      queryString += ('?version=' + dataToBeSend.version
      )
    }
    getData(endpoints.POLICY_LIST_API + queryString)
      .then(jsonResponse => {
        if (jsonResponse && jsonResponse.code === siteConstants.SUCCESS_STATUS_CODE) {
          if (jsonResponse.data && jsonResponse.data.signed_get_url) {
            if (isViewFlow) {
              dispatch(updatePreviewURL(jsonResponse.data.signed_get_url))
            } else {
              utils.downloadFile(jsonResponse.data.signed_get_url)
            }
          }
        }
      })
  }
}

export function tryFetchingCurrentPolicyInfo (data) {
  return function (dispatch) {
    let endpoint = endpoints.POLICY_LIST_API + '/' + data.policy_id
    if (data.version) {
      endpoint += '?version=' + data.version
    }
    getData(endpoint, true)
      .then(jsonResponse => {
        if (jsonResponse && jsonResponse.code === siteConstants.SUCCESS_STATUS_CODE) {
          dispatch(updateCurrentPolicyInfo(jsonResponse.data))
          utils.showToaster({ type: 'success', label: 'Success Message', message: jsonResponse.message })
        } else {
          dispatch(updateCurrentPolicyInfo(jsonResponse))
        }
      })
  }
}

export function tryAcceptingUserPolicy (policyId) {
  return function (dispatch) {
    const endpoint = endpoints.POLICY_LIST_API + '/' + policyId
    putData(endpoint, { accept: true })
      .then(jsonResponse => {
        if (jsonResponse && jsonResponse.code === siteConstants.SUCCESS_STATUS_CODE) {
          dispatch(updateCurrentPolicyInfo(jsonResponse.data))
          utils.showToaster({ type: 'success', label: 'Success Message', message: jsonResponse.message })
        } else if (jsonResponse && jsonResponse.message) {
          utils.showToaster({ type: 'error', label: 'Attention !', message: jsonResponse.message })
        }
      })
  }
}

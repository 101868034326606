import React from 'react'
import PropTypes from 'prop-types'
import '../../assets/stylesheets/shared/inputWithHeading.scss'

function InputWithHeading (props) {
  function handleBlur (e) {
    const textValue = e.target.value
    props.onBlur && typeof props.onBlur === 'function' && props.onBlur(textValue)
  }

  function handleChange (e) {
    const textValue = e.target.value
    props.onChange && typeof props.onChange === 'function' && props.onChange(textValue)
  }

  function handleFocus (e) {
    const textValue = e.target.value
    props.onFocus && typeof props.onFocus === 'function' && props.onFocus(textValue)
  }

  function handleKeyPress (e) {
    if (e.key === 'Enter') {
      props.onEnter && typeof props.onEnter === 'function' && props.onEnter()
    }
  }

  const propsList = {}
  if (!props.skipAutoFocus) {
    propsList.autoFocus = true
  }
  return (
    <div className={`input-heading-container ${props.uniqueClass} ${props.hasError ? 'error' : ''}`}>
      <div className='input-label-container'>
        <span className={`
                input-label
                ${props.labelClass || 'copy'}
                ${!props.inputHeading ? 'hidden' : ''}
                ${props.isRequired ? `${props.isRequired === 1 ? 'required' : ''}` : ''}
            `}
        >
          {props.inputHeading}
        </span>
      </div>
      {
        props.isReadOnly
          ? (
            <div className='input-text-container read-only '>
              <span className={`read-text ${props.readOnlyClass || 'big-copy-heavy'}`}>{props.value}
              </span>
            </div>
            )
          : (
            <div className='input-text-container'>
              <input
                id={props.id ? props.id : Date.now()}
                name={props.name ? props.name : ''}
                className='input-field-dark input-text'
                type={props.inputType || 'text'}
                placeholder={props.placeholder}
                value={props.value}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                autoComplete={props.autoComplete ? 'on' : 'off'}
                maxLength='255'
                disabled={!!props.checkIcon}
                {...propsList}
              />
              {props.checkIcon && <i className='pi pi-check check-icon' aria-hidden='true' />}
            </div>
            )
      }

      {props.errorText && (
        <div className='error-text-container tl'>
          <span className='ibm error-msg error-text mech-panel-sprite' />
          <span className='ibm error-text tl-text12 tl tl-pl4'>
            {props.errorText}
          </span>
        </div>
      )}
    </div>
  )
}

InputWithHeading.propTypes = {
  text: PropTypes.string,
  placeholder: PropTypes.string,
  isReadOnly: PropTypes.bool,
  inputType: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onEnter: PropTypes.func,
  onChange: PropTypes.func,
  errorText: PropTypes.string,
  readOnlyClass: PropTypes.string,
  inputHeading: PropTypes.string,
  labelClass: PropTypes.string,
  hasError: PropTypes.bool,
  skipAutoFocus: PropTypes.any,
  uniqueClass: PropTypes.string
}

export default InputWithHeading

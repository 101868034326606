import React, { useState, useEffect } from 'react'
import '../../assets/stylesheets/shared/sidebar.scss'
import * as siteConstants from '../../constants/siteConstants'
import classnames from 'classnames'
import { Link, useLocation } from 'react-router-dom'
import { menuList } from '../../helpers/sidebar'

function Sidebar (props) {
  const { actions, updateMenuExpander } = props
  const [activeSection, updateActiveSection] = useState(null)
  const [state, seState] = useState({
    activeSubMenu: '',
    showSubMenu: false,
    expandedMenu: true
  })
  const menu = menuList()

  const { pathname } = useLocation()

  useEffect(() => {
    const desiredPathName = pathname && pathname.split('/')
    const location = '/' + desiredPathName[1]
    handleSideBarActiveSection(location)
  }, [pathname])

  function handleSideBarActiveSection (activeSection) {
    switch (activeSection) {
      case siteConstants.EMPLOYEE_POLICIES_PATH:
        updateActiveSection(siteConstants.SIDEBAR_POLICIES)
        break
      case siteConstants.EMPLOYEE_CHAPTERS_PATH:
        updateActiveSection(siteConstants.SIDEBAR_CHAPTERS)
        break
      default:
        break
    }
  }

  function showMenuExpander () {
    seState({
      ...state,
      expandedMenu: !state.expandedMenu
    })
    updateMenuExpander(state.expandedMenu)
  }

  function tryLogginOutUser () {
    actions.loginActions.tryLoggingOutUser()
  }

  return (
    <>

      <div className={classnames('l-navbar', {
        expander: state.expandedMenu
      })}
      >
        <nav className='nav'>
          <div>
            <div className='nav__brand'>
              <span onClick={() => showMenuExpander()} className='nav__toggle fa fa-bars' id='nav-toggle' aria-hidden='true' />
              <span className='logo tl-text18 tl-cffffff tl-fbold tl-fsn tl-lh170 vertical-center '><img src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/TrustSpace-logo.png' className='tl-mr12' height='16px' />TrustSpace</span>
            </div>
            <div className='nav__list'>
              {menu?.map((menu, index) => {
                return (
                  <Link
                    key={index}
                    to={menu.path}
                    className={classnames('nav__link', {
                      active: activeSection === menu.menuConstant
                    })}
                  >
                    <span className='center'>
                      <span className={menu.icon} />
                    </span>
                    <span className='nav__name'>{menu.title}</span>
                  </Link>
                )
              })}
            </div>
          </div>
          <span className='nav__link' onClick={() => tryLogginOutUser()}>
            <span className='center'>
              <svg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path fillRule='evenodd' clipRule='evenodd' d='M5.59657 6.48666C5.30491 6.48666 5.07429 6.71333 5.07429 7C5.07429 7.27999 5.30491 7.51333 5.59657 7.51333H9.66634V10.7C9.66634 12.3333 8.31653 13.6667 6.64793 13.6667H3.34464C1.68281 13.6667 0.333008 12.34 0.333008 10.7067V3.29999C0.333008 1.65999 1.6896 0.333328 3.35142 0.333328H6.6615C8.31654 0.333328 9.66634 1.65999 9.66634 3.29333V6.48666H5.59657ZM12.0865 4.69346L14.0331 6.63346C14.1331 6.73346 14.1865 6.86013 14.1865 7.00013C14.1865 7.13346 14.1331 7.26679 14.0331 7.36013L12.0865 9.30013C11.9865 9.40013 11.8531 9.45346 11.7265 9.45346C11.5931 9.45346 11.4598 9.40013 11.3598 9.30013C11.1598 9.10013 11.1598 8.77346 11.3598 8.57346L12.4265 7.51346H9.66647V6.48679H12.4265L11.3598 5.42679C11.1598 5.22679 11.1598 4.90013 11.3598 4.70013C11.5598 4.49346 11.8865 4.49346 12.0865 4.69346Z' fill='#788AAA' />
              </svg>
            </span>
            <span className='nav__name'>Log Out</span>
          </span>
        </nav>
      </div>
    </>
  )
}

export default Sidebar

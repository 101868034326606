import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import InputWithHeading from '../shared/InputWithHeading'
import ButtonContainer from '../shared/ButtonContainer'

import '../../assets/stylesheets/components/login/login.scss'
import { getParameterByName } from '../../helpers/Utils'
import { enterOTPAndLogin } from '../../services/common'
import { CHANGE_PASSWORD_PATH, PANEL_DASHBOARD_URL } from '../../constants/endpoints'

function OtpValidation (props) {
  const [state, setState] = useState({
    otp: '',
    otpError: false
  })

  const { actions, loginData } = props
  const [showLoader, updateLoader] = useState(false)

  useEffect(() => {
    if (!(loginData.selectedOrgId)) {
      window.location = '/login'
    }
  }, [loginData?.userSession])

  const onOTPFocus = () => {
    setState({ ...state, otpError: false })
    actions.loginActions.resetErrors()
  }

  const onOTPFocusOut = (value) => {
    setState({ ...state, otp: value })
  }

  async function onVerifyCTA () {
    updateLoader(true)
    let otpError = false
    if (state.otp) {
      const dataToSend = {
        id: loginData?.userDetails?.id,
        organization_id: loginData?.selectedOrgId,
        sub_domain: 'employee',
        otp: Number(state.otp)
      }
      const response = await enterOTPAndLogin(dataToSend)
      updateLoader(false)
      if (response) {
        localStorage.setItem('loginToken', response?.data?.token)
        localStorage.setItem('userSession', JSON.stringify(response?.data?.user))
        if (loginData?.userDetails?.status === 'new') {
          window.location.href = CHANGE_PASSWORD_PATH
        } else {
          const redirectUrl = getParameterByName('redirecturl')
          window.location.href = redirectUrl || PANEL_DASHBOARD_URL
        }
      }
    } else {
      if (!state.otp) { otpError = true }
      setState({ ...state, otpError })
    }
  }

  return (
    <div className='panel-login-component'>
      <div className='logo-wrapper tc tl-p32 flex-center'>
        <a href='https://trustspace.io/'><img className='tl-mt6' height='24px' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/TrustSpace-logo.png' alt='TrustSpace' /></a>
        <span className='tl-text26 tl-ml12 tl-cffffff tl-f500'>TrustSpace</span>
      </div>
      <div className='login-content-inputs tc'>
        <div className='header-text tl-text40 tl-f600 tl-lh170 tl-001233 '>Verify OTP</div>
        <div className='panel-login-password-container '>

          <div className='p-relative'>
            <InputWithHeading
              id='frmEmailA'
              inputHeading='OTP'
              placeholder='Enter one time password'
              value={state.username}
              errorText='OTP Required'
              hasError={state.otpError}
              onBlur={onOTPFocusOut}
              onFocus={onOTPFocus}
              onChange={onOTPFocusOut}
              onEnter={onVerifyCTA}
              autoComplete
              name='otp'
              inputType='text'
            />
          </div>
          <div className='vertical-center'>
            <div id='login-button' className='w-100'>
              <ButtonContainer
                btnText='Verify'
                isInactive={false}
                updateInProgress={showLoader}
                onBtnClick={onVerifyCTA}
              />
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

OtpValidation.propTypes = {
  actions: PropTypes.object.isRequired,
  loginData: PropTypes.object.isRequired
}

export default OtpValidation

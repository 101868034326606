export const LOGOUT_COMPLETE = 'LOGOUT_COMPLETE'
export const LOGIN_RESET_ERRORS = 'LOGIN_RESET_ERRORS'
export const LOGIN_CALL_COMPLETE = 'LOGIN_CALL_COMPLETE'
export const LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS'
export const UPDATE_USER_ORIGIN = 'UPDATE_USER_ORIGIN'
export const UPDATE_SELECTED_USER_ORIGIN = 'UPDATE_SELECTED_USER_ORIGIN'

export const SHOW_EMPLOYEE_TAB = 'SHOW_EMPLOYEE_TAB'

export const UPDATE_POLICY_LIST = 'UPDATE_POLICY_LIST'
export const FETCHING_POLICY_LIST = 'FETCHING_POLICY_LIST'
export const UPDATE_CURRENT_POLICY_INFO = 'UPDATE_CURRENT_POLICY_INFO'
export const UPDATE_POLICY_META_DATA = 'UPDATE_POLICY_META_DATA'
export const UPDATE_PREVIEW_URL = 'UPDATE_PREVIEW_URL'

export const FETCHING_CHAPTERS_LIST = 'FETCHING_CHAPTERS_LIST'
export const UPDATE_CHAPTERS_LIST = 'UPDATE_CHAPTERS_LIST'
export const UPDATE_CURRENT_CHAPTER_INFO = 'UPDATE_CURRENT_CHAPTER_INFO'
export const UPDATE_CHAPTER_META_DATA = 'UPDATE_CHAPTER_META_DATA'
export const UPDATE_CHAPTER_PREVIEW_URL = 'UPDATE_CHAPTER_PREVIEW_URL'

import * as actionTypes from '../constants/actionTypes'
import * as endpoints from '../constants/endpoints'
import { USER_ORIGIN } from '../constants/siteConstants'
import { postData, putData } from '../helpers/apiCall'
import * as utils from '../helpers/Utils'

export function resetErrors (data) {
  return { type: actionTypes.LOGIN_RESET_ERRORS, data }
}

export function loginInProgress (data) {
  return { type: actionTypes.LOGIN_IN_PROGRESS, data }
}

export function onLoginCallComplete (data) {
  return { type: actionTypes.LOGIN_CALL_COMPLETE, data }
}

export function onlogoutSuccess (data) {
  return { type: actionTypes.LOGOUT_COMPLETE, data }
}

export function updateUserOrigin (data) {
  return { type: actionTypes.UPDATE_USER_ORIGIN, data }
}

export function updateSelectedUserOrigin (data) {
  return { type: actionTypes.UPDATE_SELECTED_USER_ORIGIN, data }
}

function handleLoginRedirection (data, originVal, navigate, isPasswordFlow, isNew) {
  const redirectUrl = utils.getParameterByName('redirecturl')
  if (originVal === USER_ORIGIN.PASSWORD || isPasswordFlow) {
    if (data?.mfa_enabled) {
      const url = redirectUrl ? '/validate?redirecturl=' + redirectUrl : '/validate'
      navigate(url)
    } else {
      window.location.href = redirectUrl || endpoints.PANEL_DASHBOARD_URL
    }
  } else {
    if (isNew) {
      window.location.href = endpoints.CHANGE_PASSWORD_PATH
    } else {
      window.location.href = redirectUrl || endpoints.PANEL_DASHBOARD_URL
    }
  }
}

export function tryCheckingUserOrigin (data) {
  return function (dispatch) {
    const dataToBeSend = {
      email: data.username
    }
    dispatch(loginInProgress())
    data && data.username &&
      postData(endpoints.CHECK_USER_ORIGIN, dataToBeSend)
        .then(jsonResponse => {
          dispatch(updateUserOrigin(jsonResponse.data))
        })
        .catch(() => dispatch(onLoginCallComplete({})))
  }
}

export function tryLoginWithEmail (data, originVal, navigate) {
  return function (dispatch) {
    const dataToBeSend = {
      email: data.username,
      sub_domain: 'employee'
    }
    if (data?.origin) {
      dataToBeSend.origin = data.origin
    }
    if (data?.token) {
      dataToBeSend.token = data.token
    }
    if (data?.password) {
      dataToBeSend.password = data.password
    }
    if (data?.organization_id) {
      dataToBeSend.organization_id = data.organization_id
    }
    dispatch(loginInProgress())
    data && data.username &&
      postData(endpoints.LOGIN_API, dataToBeSend)
        .then(jsonResponse => {
          const response = jsonResponse
          const isSuccess = !!(response && response.code && response.code === 200)
          const responseData = response.data || {}
          const isNew = !!(response && response.data && response.data.user && response.data.user.status === 'new')
          dispatch(onLoginCallComplete(jsonResponse))
          if (isSuccess) {
            const userSession = responseData || {}
            const userInfo = userSession.user || {}
            localStorage.setItem('loginToken', responseData.token)
            localStorage.setItem('userSession', JSON.stringify(userInfo))
            handleLoginRedirection(responseData, originVal, navigate, data?.password, isNew)
          }
        })
        .catch(ex => dispatch(onLoginCallComplete({})))
  }
}

export function tryLoggingOutUser () {
  localStorage.removeItem('loginToken')
  window.location.href = endpoints.PANEL_LOGIN_URL
}

export function tryChangingUserPassword (data) {
  return function (dispatch) {
    const dataToBeSend = {
      new_password: data.password
    }
    dispatch(loginInProgress())
    dataToBeSend && dataToBeSend.new_password &&
      putData(endpoints.CHANGE_PASSWORD_API, dataToBeSend)
        .then(jsonResponse => {
          utils.showToaster({ type: 'success', label: 'Success Message', message: jsonResponse.message })
          const response = jsonResponse
          const isSuccess = !!(response && response.code && response.code === 200)
          if (isSuccess) {
            setTimeout(() => {
              window.location.href = endpoints.PANEL_DASHBOARD_URL
            }, 1000)
          }
        })
        .catch(ex => dispatch(onLoginCallComplete({})))
  }
}

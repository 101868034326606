import * as actionTypes from '../constants/actionTypes'
import * as utils from '../helpers/Utils'
import * as endpoints from '../constants/endpoints'
import * as siteConstants from '../constants/siteConstants'
import { getData, putData } from '../helpers/apiCall'

export function updateChapterList (data) {
  return { type: actionTypes.UPDATE_CHAPTERS_LIST, data }
}

export function fetchingChapterList (data) {
  return { type: actionTypes.FETCHING_CHAPTERS_LIST, data }
}

export function updateCurrentChapterInfo (data) {
  return { type: actionTypes.UPDATE_CURRENT_CHAPTER_INFO, data }
}

export function updateChapterMetaData (data) {
  return { type: actionTypes.UPDATE_CHAPTER_META_DATA, data }
}

export function updatePreviewURL (data) {
  return { type: actionTypes.UPDATE_CHAPTER_PREVIEW_URL, data }
}

export function tryUpdatingPolicyMetaData (policyId, dataToSend) {
  return function (dispatch) {
    const endpoint = endpoints.POLICY_LIST_API + '/' + policyId
    putData(endpoint, dataToSend)
      .then(jsonResponse => {
        if (jsonResponse && jsonResponse.code === siteConstants.SUCCESS_STATUS_CODE) {
          utils.showToaster({ type: 'success', label: 'Success Message', message: jsonResponse.message })
          dispatch(updateChapterMetaData(jsonResponse.data))
        } else if (jsonResponse && jsonResponse.message) {
          utils.showToaster({ type: 'error', label: 'Success Message', message: jsonResponse.message })
        }
      })
  }
}

export function tryFetchingEmployeeChapters (searchedText, limit = siteConstants.PAGINATION_LIMIT, offset = 0) {
  return function (dispatch) {
    dispatch(fetchingChapterList(true))
    let queryString = '?limit=' + limit + '&offset=' + offset
    if (searchedText) {
      queryString += ('&searchString=' + searchedText)
    }
    getData(endpoints.CHAPTER_LIST_API + queryString)
      .then(jsonResponse => {
        if (jsonResponse && jsonResponse.code === siteConstants.SUCCESS_STATUS_CODE) {
          dispatch(updateChapterList(jsonResponse.data))
        }
      })
  }
}

export function tryFetchingCurrentChapterInfo (chapterId) {
  return function (dispatch) {
    const endpoint = endpoints.CHAPTER_LIST_API + '/' + chapterId
    getData(endpoint)
      .then(jsonResponse => {
        if (jsonResponse && jsonResponse.code === siteConstants.SUCCESS_STATUS_CODE) {
          dispatch(updateCurrentChapterInfo(jsonResponse.data))
          utils.showToaster({ type: 'success', label: 'Success Message', message: jsonResponse.message })
        }
      })
  }
}

export function tryAcceptingChapterDocument (docId) {
  return function (dispatch) {
    const endpoint = endpoints.CHAPTER_LIST_API + '/' + docId
    putData(endpoint, { accept: true })
      .then(jsonResponse => {
        if (jsonResponse && jsonResponse.code === siteConstants.SUCCESS_STATUS_CODE) {
          dispatch(updateCurrentChapterInfo(jsonResponse.data))
          utils.showToaster({ type: 'success', label: 'Success Message', message: jsonResponse.message })
        }
      })
  }
}

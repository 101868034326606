export const msalConfig = {
  auth: {
    clientId: 'd9c1da43-9317-4eea-adad-cb8463a4ce95',
    authority: 'https://login.microsoftonline.com/common/', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: '/'
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read']
}

export const EMPLOYEE_POLICIES_PATH = '/policies'
export const EMPLOYEE_CHAPTERS_PATH = '/chapters'
export const SIDEBAR_POLICIES = 'policies_tab'
export const SIDEBAR_CHAPTERS = 'chapters_tab'

export const PLACEHOLDER_IMAGE_URL = 'https://i.ibb.co/XscnDn8/Vector.png'

export const SUCCESS_STATUS_CODE = 200
export const UNAUTHORIZED_STATUS_CODE = 401
export const BAD_REQUEST_STATUS_CODE = 400
export const UNAUTHORIZED_MESSAGE = 'You are not authorized to perform this action'

export const POLICY_ACCEPTED = 'accepted'
export const NEW_POLICY = 'new'
export const POLICY_REJECTED = 'rejected'

export const PAGINATION_LIMIT = 20

export const USER_ORIGIN = {
  PASSWORD: 1,
  GSUITE: 2,
  AZURE: 3
}

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import * as commonEnglishFile from './assets/locales/en.json'
import * as commonGermanFile from './assets/locales/de.json'
import * as utils from './helpers/Utils'

const savedLanguage = utils.CookiesHelper.getItem('defaultLanguage')
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: commonEnglishFile,
      de: commonGermanFile
    },
    lng: savedLanguage || 'de', // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false
    }
  })

import React, { useRef, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Toast } from 'primereact/toast'

import SidebarLayout from './SidebarLayout'
import { ROUTING_WITH_SIDEBAR, ROUTING_WITHOUT_SIDEBAR } from '../../router/index'

import * as loginActions from '../../actions/login'
import * as employeeActions from '../../actions/employee'
import * as chaptersActions from '../../actions/chapters'

function Dashboard (props) {
  useEffect(() => {
    window.addEventListener('showToaster', showToast)
    return () => {
      window.removeEventListener('showToaster', showToast)
    }
  }, [])

  const myToast = useRef(null)

  function showToast (event) {
    if (event && event.detail && event.detail.message) {
      const detail = event.detail
      myToast.current.show({ severity: detail.type, summary: detail.label, detail: detail.message, life: detail.life || 3000 })
    }
  }

  return (
    <Router>
      <div className='app-container'>
        <Toast ref={myToast} />
        <Routes>
          <Route element={<SidebarLayout actions={props?.actions} />}>
            {ROUTING_WITH_SIDEBAR(props)?.map((route) => (
              <Route
                path={route.path}
                key={route.key}
                element={route.component}
              />
            ))}
          </Route>
          {ROUTING_WITHOUT_SIDEBAR(props)?.map((route) => (
            <Route
              path={route.path}
              key={route.key}
              exact={route.exact}
              element={route.component}
            />
          ))}
        </Routes>
      </div>
    </Router>
  )
}

function mapStateToProps (state) {
  return {
    loginData: state.login,
    employeeData: state.employee,
    chaptersData: state.chapters
  }
};

function mapDispatchToProps (dispatch) {
  return {
    actions: {
      loginActions: bindActionCreators(loginActions, dispatch),
      employeeActions: bindActionCreators(employeeActions, dispatch),
      chaptersActions: bindActionCreators(chaptersActions, dispatch)
    }
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)

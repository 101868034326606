import * as actionTypes from '../constants/actionTypes'
import * as initialStates from '../constants/initialStates'

export default function loginReducer (state = initialStates.LOGIN_INITIAL_STATE, action) {
  let response, isSuccess
  switch (action.type) {
    case actionTypes.LOGIN_IN_PROGRESS:
      return Object.assign({}, state, {
        loginInProgress: true
      })

    case actionTypes.LOGIN_RESET_ERRORS:
      return Object.assign({}, state, {
        loginAttemptFailed: false,
        forgotAttemptFailed: false
      })

    case actionTypes.LOGIN_CALL_COMPLETE: {
      response = action.data
      isSuccess = !!(response && response.code && response.code === 200)

      if (isSuccess) {
        return Object.assign({}, state, {
          loginFlowComplete: true,
          loginInProgress: false,
          loginAttemptFailed: false,
          userDetails: action?.data?.data?.user
        })
      } else {
        return Object.assign({}, state, {
          loginFlowComplete: false,
          loginInProgress: false,
          loginAttemptFailed: true
        })
      }
    }
    case actionTypes.UPDATE_USER_ORIGIN: {
      return Object.assign({}, state, {
        originData: action.data,
        userOrigin: action.data && action.data[0].origin,
        selectedOrgId: action.data && action.data[0].organization_id,
        userOrganizationName: action.data && action.data[0].organization_name,
        loginInProgress: false
      })
    }
    case actionTypes.UPDATE_SELECTED_USER_ORIGIN: {
      return Object.assign({}, state, {
        userOrigin: action.data.origin,
        userOrganizationName: action.data.organization_name,
        selectedOrgId: action.data.organization_id
      })
    }
    case actionTypes.LOGOUT_COMPLETE:
      return initialStates.LOGIN_INITIAL_STATE

    default:
      return state
  }
}

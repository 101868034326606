import { EMPLOYEE_POLICIES_PATH, EMPLOYEE_CHAPTERS_PATH, SIDEBAR_POLICIES, SIDEBAR_CHAPTERS } from '../constants/siteConstants'

export const menuList = () => {
  return [
    {
      title: 'Guidelines',
      icon: 'fa fa-lock',
      path: EMPLOYEE_POLICIES_PATH,
      menuConstant: SIDEBAR_POLICIES
    },
    {
      title: 'Documents',
      icon: 'fa fa-folder',
      path: EMPLOYEE_CHAPTERS_PATH,
      menuConstant: SIDEBAR_CHAPTERS
    }
  ]
}

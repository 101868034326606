import axios from 'axios'
import { getCommonHeaders, showToaster } from '../helpers/Utils'
export const getData = (endpoint, ignoreErrorHandling = false) => {
  return axios.get(endpoint, { headers: getCommonHeaders() }).then(res => {
    return res.data
  })
    .catch(function (error) {
      if (error.response) {
        if (ignoreErrorHandling) {
          return error.response.data
        } else {
          handleError(error.response)
        }
      }
    })
}

export const postData = (endpoint, data) => {
  return axios.post(endpoint, JSON.stringify(data), { headers: getCommonHeaders() }).then(res => {
    return res.data
  })
    .catch(function (error) {
      if (error.response) {
        handleError(error.response)
      }
    })
}

export const putData = (endpoint, data) => {
  return axios.put(endpoint, JSON.stringify(data), { headers: getCommonHeaders() }).then(res => {
    return res.data
  })
    .catch(function (error) {
      if (error.response) {
        handleError(error.response)
      }
    })
}

export const deleteData = (endpoint, data) => {
  const options = {
    headers: getCommonHeaders()
  }
  if (data) {
    options.data = JSON.stringify(data)
  }
  return axios.delete(endpoint, options).then(res => {
    return res.data
  })
    .catch(function (error) {
      if (error.response) {
        handleError(error.response)
      }
    })
}

export async function uploadfileToAWSS3 (fileuploadurl, file) {
  const config = {
    header: {
      'Content-Type': file.type
    }
  }
  const response = await axios.put(fileuploadurl, file, config)
  return await response
}

function handleError (error) {
  switch (error.status) {
    case 401:
      showToaster({ type: 'error', label: 'Attention!', life: 3000, message: error.data.message + ' taking you to login page' })
      setTimeout(() => {
        redirectToLogin()
      }, 4000)
      break
    case 400:
      showToaster({ type: 'error', label: 'Attention !', message: error?.data?.message })
      break
    default:
      console.warn(error)
  }
}

function redirectToLogin () {
  const pathName = window?.location?.pathname
  window.location = '/login?redirecturl=' + pathName
}

import { combineReducers } from 'redux'
import login from './login'
import employee from './employee'
import chapters from './chapters'

const primaryReducer = combineReducers({
  login,
  chapters,
  employee
})

export default primaryReducer

import { ProgressSpinner } from 'primereact/progressspinner'
import React from 'react'

const Loading = () => {
  return (
    <div className='transform-to-center'>
      <ProgressSpinner />
    </div>
  )
}

export default Loading
